import React from 'react';

import { useSelector } from 'react-redux';

import { makeStyles } from 'tss-react/mui';

import { useIntl } from 'ha/i18n';
import { getIsAuthenticated } from 'ha/modules/AuthLogic/selectors';
import { ScreenSize } from 'ha/modules/UI/constants';

import { ListingsTabContainer } from 'ha/modules/ListingsTabContainer';
import { SectionLayout } from 'ha/pages/Home/layouts';

import { FavoriteListingsUnauthenticatedPlaceHolder } from './FavoriteListingsPlaceHolder';
import { FavoriteListingsSlider } from './FavoriteListingsSlider';
import { RecentlyViewedListingsSlider } from './RecentlyViewedListingsSlider';

const useStyles = makeStyles()(theme => ({
  section: {
    [theme.breakpoints.up(ScreenSize.MD)]: {
      paddingTop: theme.utils.spacing('ref/spacing/0'),
    },
  },
  tabs: {
    marginInline: 'auto',
    maxWidth: '540px',
    marginBottom: theme.utils.spacing('ref/spacing/10'),
  },
  root: {
    marginTop: `-${theme.utils.spacing('ref/spacing/6')}px`,
    [theme.breakpoints.down(ScreenSize.MD)]: {
      marginTop: theme.utils.spacing('ref/spacing/0'),
    },
  },
}));

export const RecentlyViewedListings = () => {
  const { T } = useIntl();
  const { classes } = useStyles();
  const isAuthenticated = useSelector(getIsAuthenticated);

  const tabsData = [
    {
      value: 0,
      label: T('recent_searches.tabs.recently_viewed'),
      testId: 'RecentlyViewedTab',
      tabName: 'Recently Viewed',
      content: <RecentlyViewedListingsSlider />,
    },
    {
      value: 1,
      label: T('recent_searches.tabs.your_favorites'),
      testId: 'YourFavoritesTab',
      tabName: 'Favorites',
      content: isAuthenticated ? (
        <FavoriteListingsSlider isActive />
      ) : (
        <FavoriteListingsUnauthenticatedPlaceHolder />
      ),
    },
  ];

  return (
    <div className={classes.root}>
      <SectionLayout className={classes.section}>
        <SectionLayout.Container>
          <ListingsTabContainer
            tabsData={tabsData}
            className={classes.tabs}
            rootClassName={classes.root}
          />
        </SectionLayout.Container>
      </SectionLayout>
    </div>
  );
};
